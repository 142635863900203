<template>
  <b-row>
    <b-col md="2">
      <filters-card
          :start.sync="start"
          :end.sync="end"
          :isInvoicedSelected.sync="isInvoicedSelected"
      />
    </b-col>

    <b-col md="10">
      <articles-card
          :start="start"
          :end="end"
          :is-sales="true"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import moment from 'moment'
import FiltersCard from './components/_filters'
import useAPI from '../../../utils/useAPI'
import ArticlesCard from '../../../components/indicators/articles/ArticlesCard'

export default {
  components: {
    FiltersCard,
    ArticlesCard,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentYear = parseInt(new Date().getFullYear())
    const start = ref(moment(currentYear + '-07-01').format('YYYY-MM-DD'))
    const end = ref(moment((currentYear + 1) + '-06-30').format('YYYY-MM-DD'))
    const isInvoicedSelected = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components

      // Data
      start,
      end,
      isInvoicedSelected,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>